<template>
    <div class="container">
      <div>主页面</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'JuniorCollegeMajor',
  };
  </script>
  
  <style scoped>
.container {
  padding: 20px;
  background-color: #ffffff;
  min-height: 80vh;
}
  </style>
  